import React from 'react';
import styled from 'styled-components';

interface ChooseCompanyProps {
  setCompany: React.Dispatch<React.SetStateAction<string>>;
}

const ChooseCompany: React.FC<ChooseCompanyProps> = ({ setCompany }) => {
  return (
    <Centered>
      <Container>
        <CompanyButton onClick={() => setCompany('texasArtificial')}>
          Texas Artificial
        </CompanyButton>
        <CompanyButton onClick={() => setCompany('rehabTech')}>
          Rehab Tech
        </CompanyButton>
        <CompanyButton onClick={() => setCompany('crownCity')}>
          Crown City
        </CompanyButton>
        <CompanyButton onClick={() => setCompany('braceYourself')}>
          Brace Yourself
        </CompanyButton>
      </Container>
    </Centered>
  );
};

export default ChooseCompany;

const Centered = styled.div`
  display: flex;
  justify-content: center;
`;
const Container = styled.div`
  width: 100%;
  max-width: 500px;
  margin-top: 20vh;
  display: grid;
  gap: 1.5rem;
`;

const CompanyButton = styled.button`
  background: white;
  color: black;
  height: 40px;
`;
