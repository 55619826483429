import React from 'react';
import styled from 'styled-components';
import OneTimeCharge from './OneTimeCharge';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Load Specific Stripe
const stripePromise = (company: string) => {
  //
  let key = process.env.RUBIK_STRIPE_PUBLIC;
  switch (company) {
    case 'texasArtificial':
      key = process.env.TEXAS_ARTIFICIAL_STRIPE_PUBLIC;
      break;

    case 'rehabTech':
      key = process.env.REHAB_TECH_STRIPE_PUBLIC;
      break;

    case 'braceYourself':
      key = process.env.BRACE_YOURSELF_STRIPE_PUBLIC;
      break;

    case 'crownCity':
      key = process.env.CROWN_CITY_STRIPE_PUBLIC;
      break;
  }

  console.log('Key used:', company, key);
  return loadStripe(key as string);
};

interface TerminalProps {
  company: string;
}

const Terminal: React.FC<TerminalProps> = ({ company }) => {
  if (company)
    return (
      <>
        <Container>
          <Elements stripe={stripePromise(company)}>
            <OneTimeCharge company={company} />
          </Elements>
        </Container>
      </>
    );
  return null;
};

export default Terminal;

const Container = styled.div`
  padding-top: 10vh;
  display: flex;
  justify-content: center;
`;
