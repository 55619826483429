import React, { useState, useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router
} from 'react-router-dom';
import { useAuth0 } from './contexts/auth0-context';
import Terminal from './pages/Terminal';
import Loading from './pages/Loading';
import ChooseCompany from './pages/ChooseCompany';
import Success from './pages/Success';

const App: React.FC = () => {
  const { isAuthenticated, loginWithRedirect, loading, user } = useAuth0();

  const [company, setCompany] = useState<string>('');

  useEffect(() => {
    if (!isAuthenticated && !loading) loginWithRedirect();
  }, [loading]);

  if (isAuthenticated && !company)
    return (
      <Router>
        <Switch>
          <Route path="/">
            <ChooseCompany setCompany={setCompany} />
          </Route>
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    );

  if (isAuthenticated && company)
    return (
      <Router>
        <Switch>
          <Route path="/terminal/success">
            <Success />
          </Route>
          <Route path="/terminal">
            <Terminal company={company} />
          </Route>
          <Route>
            <Redirect to="/terminal" />
          </Route>
        </Switch>
      </Router>
    );

  return <Loading />;
};

export default App;
